import {
  useWSModal,
  WSElement,
  WSMessageBox,
  WSText
} from "@wingspanhq/fe-component-library";
import React from "react";
import {
  getCurrentIRSQuarter,
  getInternalProcessingDeadline
} from "../../../shared/utils/taxes";
import { wsMoment as moment } from "@wingspanhq/utils/dist/date/wsMoment";

export const TaxesModalQuarterInfo: React.FC = () => {
  const quarter = getCurrentIRSQuarter();

  const currentDueDate = moment(quarter.dueDate, "MMM DD, YYYY");
  const internalProcessingDeadline = getInternalProcessingDeadline(
    quarter.dueDate,
    "MMM DD, YYYY"
  );

  // TODO: remove after 2025
  const isCustomQ1Deadline = quarter.dueDate === "Apr 15, 2025";

  return (
    <WSElement>
      {isCustomQ1Deadline ? (
        <WSText.ParagraphSm mb="M">
          To ensure timely processing and avoid penalties, please submit your
          quarterly estimated tax payments by{" "}
          <b>{internalProcessingDeadline.format("MMMM D, YYYY h:mm A")} EST</b>.
          The official IRS due date is{" "}
          <b>{currentDueDate.format("MMMM D, YYYY")}</b>.
        </WSText.ParagraphSm>
      ) : (
        <WSText.ParagraphSm mb="M">
          To ensure timely processing and avoid penalties, please submit your
          quarterly estimated tax payments by{" "}
          <b>{internalProcessingDeadline.format("MMMM D, YYYY h:mm A")} EST</b>,
          which is 4 business days before the IRS due date of{" "}
          <b>{currentDueDate.format("MMMM D, YYYY")}</b>.
        </WSText.ParagraphSm>
      )}
      <WSMessageBox.Info noBorder>
        <WSText.Heading5 mb="M">Important:</WSText.Heading5>
        <WSText.ParagraphSm mb="M" color="gray600">
          If your payment request is submitted after our processing deadline of{" "}
          <b>{internalProcessingDeadline.format("MMMM D, YYYY h:mm A")} EST</b>,
          we cannot guarantee it will be processed by the IRS due date of{" "}
          <b>{currentDueDate.format("MMMM D, YYYY")}</b>. Late payments may be
          applied to the next quarter, and penalties from the IRS may apply.
        </WSText.ParagraphSm>
      </WSMessageBox.Info>

      <WSText.ParagraphSm mt="XL" color="gray500">
        TIP: If you’re tempted to withdraw for non-emergencies, we recommend
        making regular payments to the IRS.
      </WSText.ParagraphSm>
    </WSElement>
  );
};

export const useModalTaxesQuarterInfo = () =>
  useWSModal(TaxesModalQuarterInfo, {
    title: "When should I send my quarterly estimate payment to the IRS?",
    size: "M"
  });
