import {
  Currency,
  currencyConfig
} from "@wingspanhq/utils/dist/currency/currencies";

export type CurrencyConfigItem = {
  code: Currency;
  symbol: string;
  name: string;
  exponent: number;
  symbolPosition: string;
  pattern: {
    regex: RegExp;
    format: string;
  };
};

export const CURRENCIES: CurrencyConfigItem[] = Object.values(Currency)
  .sort((a, b) => {
    if (a === b) return 0;
    return a.localeCompare(b);
  })
  .map(currency => ({
    code: currency,
    ...currencyConfig[currency]
  }));

export const CURRENCY_OPTIONS = CURRENCIES.map(c => ({
  value: c.code,
  label: `${c.name} (${c.code})`,
  searchText: c.name + " " + c.code
}));

export const SUPPORTED_CONVERSION_CURRENCIES = [
  {
    value: "AED",
    label: "AED"
  },
  {
    value: "ARS",
    label: "ARS"
  },
  {
    value: "AUD",
    label: "AUD"
  },
  {
    value: "BDT",
    label: "BDT"
  },
  {
    value: "BRL",
    label: "BRL"
  },
  {
    value: "CAD",
    label: "CAD"
  },
  {
    value: "CHF",
    label: "CHF"
  },
  {
    value: "CLP",
    label: "CLP"
  },
  {
    value: "CNY",
    label: "CNY"
  },
  {
    value: "COP",
    label: "COP"
  },
  {
    value: "CRC",
    label: "CRC"
  },
  {
    value: "CZK",
    label: "CZK"
  },
  {
    value: "DKK",
    label: "DKK"
  },
  {
    value: "EUR",
    label: "EUR"
  },
  {
    value: "GBP",
    label: "GBP"
  },
  {
    value: "GHS",
    label: "GHS"
  },
  {
    value: "HKD",
    label: "HKD"
  },
  {
    value: "HUF",
    label: "HUF"
  },
  {
    value: "IDR",
    label: "IDR"
  },
  {
    value: "ILS",
    label: "ILS"
  },
  {
    value: "INR",
    label: "INR"
  },
  {
    value: "JPY",
    label: "JPY"
  },
  {
    value: "KES",
    label: "KES"
  },
  {
    value: "KRW",
    label: "KRW"
  },
  {
    value: "LKR",
    label: "LKR"
  },
  {
    value: "MAD",
    label: "MAD"
  },
  {
    value: "MXN",
    label: "MXN"
  },
  {
    value: "MYR",
    label: "MYR"
  },
  {
    value: "NGN",
    label: "NGN"
  },
  {
    value: "NOK",
    label: "NOK"
  },
  {
    value: "NZD",
    label: "NZD"
  },
  {
    value: "PHP",
    label: "PHP"
  },
  {
    value: "PKR",
    label: "PKR"
  },
  {
    value: "PLN",
    label: "PLN"
  },
  {
    value: "RON",
    label: "RON"
  },
  {
    value: "SAR",
    label: "SAR"
  },
  {
    value: "SEK",
    label: "SEK"
  },
  {
    value: "SGD",
    label: "SGD"
  },
  {
    value: "THB",
    label: "THB"
  },
  {
    value: "TRY",
    label: "TRY"
  },
  {
    value: "TWD",
    label: "TWD"
  },
  {
    value: "USD",
    label: "USD"
  },
  {
    value: "VND",
    label: "VND"
  },
  {
    value: "ZAR",
    label: "ZAR"
  }
];
