import { WithholdingTransactionType } from "@wingspanhq/bookkeeping/dist/lib/interfaces";
import { TaxQuarter } from "@wingspanhq/bookkeeping/dist/lib/interfaces/taxEstimate";
import {
  useModalOldContext,
  useWSSnackbar,
  WSAmountInput,
  WSButton,
  WSCentered,
  WSContainer,
  WSFlexBox,
  WSFormOld,
  WSIcon,
  WSLayout,
  WSLoader,
  WSSelectOld,
  WSText
} from "@wingspanhq/fe-component-library";
import { formatMoney } from "accounting";
import { wsMoment as moment } from "@wingspanhq/utils/dist/date/wsMoment";
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useBrowserPageTitle } from "../../../components/BrowserPageTitle/BrowserPageTitle";
import { WSErrorMessage } from "../../../components/WSErrorMessage/WSErrorMessage";
import { WSQueries } from "../../../query/WSQuery";
import { useUserId } from "../../../query/hooks/helpers";
import { useCreatePendingWithholding } from "../../../query/taxes/mutations";
import {
  usePendingWithholdingList,
  useWithholdingBalance
} from "../../../query/taxes/queries";
import { useActivities, useMemberProfile } from "../../../query/users/queries";
import { CurrencyUSD } from "../../../services/bookkeeping";
import {
  getCurrentIRSQuarter,
  getInternalProcessingDeadline,
  getInternalProcessingDeadlineYear,
  getIRSQuarters
} from "../../../shared/utils/taxes";
import { validatorAmount } from "../../../utils/validators";
import { MODAL_ADD_SSN, TaxesModalAddSSN } from "../modals/TaxesModalAddSSN";
import {
  MODAL_CONSENT_EFTPS,
  TaxesModalConsentEFTPS
} from "../modals/TaxesModalConsentEFTPS";
import { useModalTaxesQuarterInfo } from "../modals/TaxesModalQuarterInfo";
import { selectorIsSendToIRSDisabled } from "../selectors/selectorIsSendToIRSDisabled";

const currentYear = getInternalProcessingDeadlineYear();
const currentQuarter = getCurrentIRSQuarter();
const QUARTERS_OPTIONS = getIRSQuarters(currentYear).map(q => ({
  value: `Q${q.quarter} ${currentYear}`,
  label: `Q${q.quarter} ${currentYear} ${
    currentQuarter.quarter === q.quarter ? "(Current quarter)" : ""
  }`
}));

const internalProcessingDeadline = getInternalProcessingDeadline(
  currentQuarter.dueDate,
  "MMM DD, YYYY"
);
const internalDeadlineFromNow = internalProcessingDeadline.fromNow(true);

export const getWSTaxQuarter = () => {
  const quarter = currentQuarter.quarter;
  let wsCurrentQuarter: TaxQuarter;

  switch (quarter) {
    case 1:
      wsCurrentQuarter = TaxQuarter.q1;
      break;
    case 2:
      wsCurrentQuarter = TaxQuarter.q2;
      break;
    case 3:
      wsCurrentQuarter = TaxQuarter.q3;
      break;
    default:
      wsCurrentQuarter = TaxQuarter.q4;
  }

  return wsCurrentQuarter;
};

export const TaxesSendToIRS: React.FC = () => {
  const history = useHistory();
  const userId = useUserId();
  const { openSnackbar } = useWSSnackbar();
  const { openModal } = useModalOldContext();
  const modalTaxInfo = useModalTaxesQuarterInfo();

  const queryMemberProfile = useMemberProfile(userId);
  const queryActivity = useActivities(userId);
  const queryWithholdingBalance = useWithholdingBalance();
  const queryPendingWithholdingList = usePendingWithholdingList();

  const [createPendingWithholding, createPendingWithholdingMeta] =
    useCreatePendingWithholding();

  useBrowserPageTitle("Taxes - Pay Federal Taxes", "");

  return (
    <WSQueries
      queries={{
        queryWithholdingBalance,
        queryMemberProfile,
        queryActivity,
        queryPendingWithholdingList
      }}
      renderLoader={() => (
        <WSLayout
          headerLeft={
            <WSIcon
              block
              name="chevron-left"
              size="M"
              color="gray500"
              onClick={() => history.goBack()}
            />
          }
          line
        >
          <WSLoader.Spinner mt="XL" />
        </WSLayout>
      )}
    >
      {({
        queryWithholdingBalanceData,
        queryMemberProfileData,
        queryPendingWithholdingListData,
        queryActivityData
      }) => {
        const availableBalance = queryWithholdingBalanceData.available || 0;

        const isDisabled = selectorIsSendToIRSDisabled(
          queryPendingWithholdingListData,
          currentYear,
          getWSTaxQuarter()
        );

        return (
          <WSFormOld
            onSubmit={async formData => {
              if (!queryMemberProfileData.profile.ssnProvided) {
                await new Promise((onSuccess, onFail) =>
                  openModal(MODAL_ADD_SSN, { onSuccess, onFail })
                );
              }

              if (!queryActivityData.events.eftpsEnrolledAt) {
                await new Promise((onSuccess, onFail) =>
                  openModal(MODAL_CONSENT_EFTPS, { onSuccess, onFail })
                );
              }

              await createPendingWithholding(
                {
                  amount: formData.amount,
                  type: WithholdingTransactionType.TaxPayment,
                  currency: CurrencyUSD,
                  quarter: getWSTaxQuarter(),
                  year: currentYear
                },
                {
                  onSuccess: () => {
                    openSnackbar({
                      message: "Successfully sent!",
                      duration: 3000,
                      type: "success"
                    });

                    history.push("/member/taxes/dashboard");
                  }
                }
              );
            }}
            defaultValues={{
              amount: undefined as any as number,
              quarter: `Q${currentQuarter.quarter} ${currentYear}`
            }}
            validationSchema={Yup.object().shape({
              amount: validatorAmount(availableBalance),
              quarter: Yup.string().required("Quarter is required")
            })}
          >
            <TaxesModalAddSSN />
            <TaxesModalConsentEFTPS />
            <WSLayout
              headerLeft={
                <WSIcon
                  block
                  name="chevron-left"
                  size="M"
                  color="gray500"
                  onClick={() => history.goBack()}
                />
              }
              headerCenter={
                <WSText weight="medium">
                  Send payment to IRS - Q{currentQuarter.quarter} {currentYear}
                </WSText>
              }
              line
            >
              <WSContainer verticalPadding>
                <WSText mt="M" mb="2XL" align="center">
                  We will submit your payment to the IRS on your behalf. To
                  ensure timely processing, please submit your payment by{" "}
                  <b>
                    {internalProcessingDeadline.format("MMMM D, YYYY h:mm A")}{" "}
                    EST
                  </b>
                  .
                </WSText>

                <WSCentered span={{ s: "8", m: "6", xl: "4" }}>
                  <WSFormOld.Field
                    name="amount"
                    mb="XL"
                    component={WSAmountInput}
                  />
                  <WSText.ParagraphSm align="center" color="gray500">
                    {formatMoney(availableBalance)} available
                  </WSText.ParagraphSm>

                  <WSFormOld.Field
                    name="quarter"
                    mb="XL"
                    label="Time period"
                    hidden={true}
                    component={WSSelectOld}
                    componentProps={{
                      options: QUARTERS_OPTIONS
                    }}
                  />
                </WSCentered>

                <WSCentered span={{ s: "8", m: "6", xl: "4" }}>
                  {isDisabled ? (
                    <WSText.ParagraphSm color="garnet" my="XL" align="center">
                      It seems you've already submitted an estimated tax payment
                      for this quarter. You can either cancel and resubmit your
                      payment now, or wait to make a new payment next quarter.
                    </WSText.ParagraphSm>
                  ) : (
                    <WSErrorMessage
                      mb="XL"
                      data-testid="pendingTransactionSubmit"
                      error={createPendingWithholdingMeta.error}
                      contextKey="PendingWithholding"
                    />
                  )}

                  <WSFormOld.SubmitButton
                    fullWidth
                    mt="2XL"
                    type="submit"
                    data-testid="submitSendToIRS"
                    disabled={isDisabled}
                  >
                    Send request
                  </WSFormOld.SubmitButton>
                  <WSButton.Tertiary
                    fullWidth
                    mt="M"
                    mb="3XL"
                    type="button"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </WSButton.Tertiary>

                  <WSText.ParagraphSm>
                    To ensure your payment is processed on time, please submit
                    it by our internal deadline.
                  </WSText.ParagraphSm>
                  <WSFlexBox.CenterY mt="M" wrap="nowrap">
                    <WSText.ParagraphSm color="gray600">
                      Internal processing deadline is in{" "}
                      {internalDeadlineFromNow} (
                      {internalProcessingDeadline.format("MMMM D, YYYY h:mm A")}{" "}
                      EST). IRS due date is{" "}
                      {moment(currentQuarter.dueDate, "MMM DD, YYYY").format(
                        "MMMM D, YYYY"
                      )}
                      .
                    </WSText.ParagraphSm>
                    <WSIcon
                      block
                      name="info-circle"
                      ml="S"
                      color="gray600"
                      onClick={() => modalTaxInfo.open()}
                    />
                  </WSFlexBox.CenterY>
                </WSCentered>
              </WSContainer>
            </WSLayout>
          </WSFormOld>
        );
      }}
    </WSQueries>
  );
};
